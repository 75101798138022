import {
  GiftCard,
  GiftCardResponse,
} from '@/features/gift-card/gift-card.schema';
import { useFetch } from '@/hooks/fetch/use-fetch';
import { giftCardListOptions } from '@/hooks/gift-cards/use-gift-cards';
import {
  AllLoyaltyProgramTypes,
  loyaltyProgramByTypeAndIdOptions,
} from '@/hooks/loyalty-programs/use-loyalty-program-by-type-and-id';
import {
  useQueries,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  LoyaltyProgramTypeMapper,
  RecommendedProductsData,
  RecommendedProductsDataItemTypes,
} from '../types';

export function useRecommendedProducts(
  recommendedProducts: RecommendedProductsData,
  enabled = true,
): UseQueryResult<GiftCard | AllLoyaltyProgramTypes>[] {
  const fetch = useFetch();
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return useQueries({
    // @ts-expect-error TODO: fix this
    queries: recommendedProducts.map((product) => {
      if (product.type !== RecommendedProductsDataItemTypes.GiftCard) {
        return loyaltyProgramByTypeAndIdOptions({
          id: product.id,
          loyaltyProgramType: LoyaltyProgramTypeMapper[product.type]!,
          fetch,
          queryClient,
          enabled,
        });
      }
      return giftCardListOptions({
        ids: [product.id],
        includeUnavailable: true,
        enabled,
        select: (data: GiftCardResponse): GiftCard => data.data[0],
      });
    }),
  }) as UseQueryResult<GiftCard | AllLoyaltyProgramTypes>[];
}
