'use client';

import {
  Carousel,
  CarouselContent,
  CarouselProps,
} from '@/design-system-components/shared-carousel/carousel';
import { CarouselArrowWithPagination } from '@/design-system-components/shared-carousel/carousel-arrow-with-pagination';
import { CarouselDotButtons } from '@/design-system-components/shared-carousel/carousel-button';
import { cn } from '@/utils/tailwind';
import React, {
  ComponentProps,
  ElementRef,
  forwardRef,
  isValidElement,
} from 'react';

export const HomepageProductsCarousel = forwardRef<
  ElementRef<typeof Carousel>,
  ComponentProps<typeof Carousel> & {
    titleComponent?: React.ReactNode;
    carouselProps?: CarouselProps;
    viewableClassName?: string;
    carouselWrapperClassName?: string;
    showPagination?: boolean;
    hideOnNotSlidable?: boolean;
  }
>(
  (
    {
      className,
      viewableClassName,
      carouselProps,
      titleComponent,
      carouselWrapperClassName,
      showPagination = false,
      hideOnNotSlidable = false,
      children,
      opts,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="overflow-hidden">
        <Carousel
          className={cn('flex flex-col', className)}
          opts={{
            watchDrag: opts?.watchDrag ?? true,
            breakpoints: {
              '(min-width: 1024px)': {
                watchDrag: false,
              },
            },
          }}
          debug={
            isValidElement(titleComponent)
              ? titleComponent.props.children
              : null
          }
          ref={ref}
          {...carouselProps}
          {...props}
        >
          <div className="flex w-full flex-row items-center">
            {titleComponent ?? null}

            <div className="ml-auto hidden flex-row gap-2 lg:flex">
              <CarouselArrowWithPagination
                showPagination={showPagination}
                hideOnNotSlidable={hideOnNotSlidable}
              />
            </div>
          </div>

          <CarouselContent
            viewableClassName={viewableClassName}
            className={carouselWrapperClassName}
          >
            {children}
          </CarouselContent>

          <CarouselDotButtons
            wrapperClassName="my-0 flex lg:hidden"
            variant="variant2"
            hideOnNotSlidable={hideOnNotSlidable}
          />
        </Carousel>
      </div>
    );
  },
);

HomepageProductsCarousel.displayName = 'HomepageProductsCarousel';
