'use client';

import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { GiftCardFilter } from '@/app/[locale]/products/gift-cards/type';
import { useHermes } from '@/auth/use-hermes';
import { defaultPageSize } from '@/design-system-components/pagination/pagination-config';
import {
  GiftCardResponse,
  giftCardResponseSchema,
  giftCardSchema,
} from '@/features/gift-card/gift-card.schema';
import { useFetch } from '@/hooks/fetch/use-fetch';
import { queryKeys } from '@/queries/query-factory';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { z } from 'zod';
import { useAuthFetchKy } from '../fetch/use-auth-fetch-ky';

// TODO: Use queryOptions once upgrade to TanStack V5
export function giftCardByIdOptions({
  id,
  enabled = true,
  fetch,
  queryClient,
}: {
  id: string;
  enabled?: boolean;
  fetch: ReturnType<typeof useFetch>;
  queryClient: ReturnType<typeof useQueryClient>;
}) {
  const baseUrl = getClientUrl();

  return {
    ...queryKeys.giftCards.detail(id),
    queryFn: () =>
      fetch(`${baseUrl}/api/products/gift_cards/${id}`).then((data) => {
        return validateSchema({
          data,
          schemaName: 'gift-card-schema',
          zodSchema: giftCardSchema,
        });
      }),
    initialData: () => {
      // Using the pull approach
      // https://tkdodo.eu/blog/seeding-the-query-cache#pull-approach
      let data;
      // since the list page use pagination so we need to find giftCard in all listing queries
      // https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientgetqueriesdata
      queryClient
        .getQueriesData<GiftCardResponse>({
          queryKey: queryKeys.giftCards.list._def,
        })
        ?.find(([_queryKey, giftCards]) => {
          data = giftCards?.data.find((giftCard) => {
            return giftCard.id === id;
          });
          return data;
        });
      return data;
    },
    staleTime: 1000 * 60 * 5,
    enabled: !!id && enabled,
  };
}

export function useGiftCardById(id: string, enabled: boolean = true) {
  const fetch = useFetch();
  const queryClient = useQueryClient();

  const { isLoading, isError, data, error, isFetched } = useQuery(
    giftCardByIdOptions({ id, fetch, queryClient, enabled }),
  );
  return {
    isLoading,
    isError,
    error,
    isFetched,
    giftCard: data,
  };
}

type UseGiftCardParams = {
  filter?: GiftCardFilter;
  search?: string;
  pageSize?: number;
  pageNumber?: number;
  sortBy?: 'name' | '-name' | 'personalization';
  ids?: string[];
  includeUnavailable?: boolean;
};

export function giftCardListOptions<
  T extends z.ZodType<GiftCardResponse>,
  TData = z.infer<T>,
>({
  filter,
  search,
  sortBy,
  pageSize,
  pageNumber,
  ids,
  includeUnavailable,
  enabled,
  select,
}: Omit<
  UseQueryOptions<GiftCardResponse, unknown, TData>,
  'queryKey' | 'queryFn'
> &
  UseGiftCardParams) {
  const authFetch = useAuthFetchKy;
  const baseUrl = getClientUrl();

  const params = [
    ['filter[categories][$any]', filter],
    ['search[name]', search],
    ['page[size]', pageSize?.toString()],
    ['page[number]', pageNumber?.toString()],
    ['sort', sortBy],
    ['includeUnavailable', includeUnavailable?.toString()],
  ] as const;

  const searchParams = new URLSearchParams();
  for (const [key, value] of params) {
    if (value) {
      searchParams.set(key, value);
    }
  }

  if (ids) {
    for (const id of ids) {
      searchParams.append('ids[]', id);
    }
  }

  if (filter === GiftCardFilter.All) {
    searchParams.delete('filter[categories][$any]');
  }
  const url = `${baseUrl}/api/products/gift_cards?${searchParams}`;

  return {
    ...queryKeys.giftCards.list(Object.fromEntries(searchParams.entries())),
    queryFn: async ({ signal }: QueryFunctionContext<QueryKey>) => {
      const data = await authFetch.get(url, { signal }).json();

      return validateSchema({
        data,
        schemaName: 'gift-card-array-schema',
        zodSchema: giftCardResponseSchema,
      });
    },
    staleTime: 1000 * 60 * 5,
    enabled,
    select,
  };
}

export function useGiftCards({
  filter,
  search = '',
  sortBy = 'name',
  pageSize = defaultPageSize,
  pageNumber = 1,
}: UseGiftCardParams) {
  const hermes = useHermes();

  return useQuery({
    ...giftCardListOptions({
      filter,
      search,
      sortBy,
      pageSize,
      pageNumber,
      enabled: hermes.loggedIn,
    }),
  });
}
